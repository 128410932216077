// import { useToast } from 'vue-toastification';

export function useApi() {
  // const toast = useToast();
  const config = useRuntimeConfig();
  const baseURL = config.public.baseURL;
  const env = config.public.env;

  const logRequest = (url, method, headers, body, response, error = null) => {
    if (env !== 'dev') return;
    const boxLine = '═'.repeat(50);
    const boxTitle = error ? '❌ ERROR' : '✅ SUCCESS';

    console.log(`
    ╔${boxLine}╗
    ║ ${boxTitle} - ${method} Request to ${url}
    ╟${boxLine}╢
    ║ URL: ${url}
    ║ Method: ${method}
    ║ Headers: ${JSON.stringify(headers, null, 2)}
    ║ Body: ${body ? JSON.stringify(body, null, 2) : 'None'}
    ╟${boxLine}╢
    ║ Response: ${response ? JSON.stringify(response, null, 2) : 'No Response'}
    ║ Error: ${error ? JSON.stringify(error.message, null, 2) : 'None'}
    ╚${boxLine}╝
        `);
  };

  const callApi = async (url, method, body = null, headers = {}) => {
    const userInfo = useCookie('subagent_userInfo');
    const language = useCookie('i18n_redirected').value || 'en';
    const token = useCookie('token').value || '';
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    try {
      const options = {
        method,
        headers: {
          Accept: 'application/json, text/plain, */*',
          Authorization: `Bearer ${token}`,
          'Accept-Language': language,
          'X-Timezone': userTimezone,
          ...headers,
        },
      };

      let apiUrl = `${baseURL}/${url}`;
      if (userInfo) {
        const subdomain = userInfo.value?.subDomain;
        if (subdomain) {
          apiUrl = `https://${subdomain}.${apiUrl}`;
        }
      }

      const { data, status, error } = await useFetch(`${apiUrl}`, {
        method,
        headers: options.headers,
        body: body ? body : undefined,
      });

      if (error.value) {
        logRequest(url, method, headers, body, null, error.value);
        // toast.error(error.value.message || 'API request failed');
      }

      logRequest(url, method, headers, body, data.value);
      return { data: data.value, status: status.value, error: error.value?.data };
    } catch (error) {
      if (env === 'local') {
        console.error('Error:', error);
      }
      // toast.error(`Error: ${error.message}`);
      return { data: null, status: 'error', error: error.message };
    }
  };

  // Separate methods for GET and POST
  const get = (url, headers = {}) => callApi(url, 'GET', null, headers);
  const post = (url, body, headers = {}) => callApi(url, 'POST', body, headers);
  const put = (url, body, headers = {}) => callApi(url, 'PUT', body, headers);
  const _delete = (url, body, headers = {}) => callApi(url, 'DELETE', body, headers);

  return {
    get,
    post,
    put,
    _delete,
  };
}
