import { default as index2n1MxXcUoAMeta } from "/root/project/frontend/apps/b2c/pages/about-us/index.vue?macro=true";
import { default as indexpX9Y9zO6cvMeta } from "/root/project/frontend/apps/b2c/pages/apply/index.vue?macro=true";
import { default as institute7fdp3SLrjMMeta } from "/root/project/frontend/apps/b2c/pages/apply/institute.vue?macro=true";
import { default as indexNFclpzlpU9Meta } from "/root/project/frontend/apps/b2c/pages/apply/new/index.vue?macro=true";
import { default as reviewD3IkhC6SMNMeta } from "/root/project/frontend/apps/b2c/pages/apply/review.vue?macro=true";
import { default as forgot_45password99o1FuZUChMeta } from "/root/project/frontend/apps/b2c/pages/auth/forgot-password.vue?macro=true";
import { default as loginBDzoMYYdnGMeta } from "/root/project/frontend/apps/b2c/pages/auth/login.vue?macro=true";
import { default as new_45passwordkucjtRPEpeMeta } from "/root/project/frontend/apps/b2c/pages/auth/new-password.vue?macro=true";
import { default as signupXmPXmlkGYYMeta } from "/root/project/frontend/apps/b2c/pages/auth/signup.vue?macro=true";
import { default as verifyHxlooHpVtJMeta } from "/root/project/frontend/apps/b2c/pages/auth/verify.vue?macro=true";
import { default as indexsawnB61pYaMeta } from "/root/project/frontend/apps/b2c/pages/book-appointment/index.vue?macro=true";
import { default as indexKHAzBoynroMeta } from "/root/project/frontend/apps/b2c/pages/course/[id]/index.vue?macro=true";
import { default as indexyaZfY1TnuNMeta } from "/root/project/frontend/apps/b2c/pages/destinations/index.vue?macro=true";
import { default as index81TfcPycrBMeta } from "/root/project/frontend/apps/b2c/pages/index.vue?macro=true";
import { default as _91id_93YqgpTaVjRGMeta } from "/root/project/frontend/apps/b2c/pages/institute/[id].vue?macro=true";
import { default as _91id_93mv8NHubUPwMeta } from "/root/project/frontend/apps/b2c/pages/institutes/[id].vue?macro=true";
import { default as _91id_9381hERy8HZxMeta } from "/root/project/frontend/apps/b2c/pages/institutes/course/[id].vue?macro=true";
import { default as indexzyhseSRW1HMeta } from "/root/project/frontend/apps/b2c/pages/listApplication/[id]/index.vue?macro=true";
import { default as _91id_93faAFtBf6jXMeta } from "/root/project/frontend/apps/b2c/pages/listApplication/files/[id].vue?macro=true";
import { default as indexR3FIqguiJ3Meta } from "/root/project/frontend/apps/b2c/pages/listApplication/index.vue?macro=true";
import { default as CoursesgqOzr8KJAoMeta } from "/root/project/frontend/apps/b2c/pages/new/[category]/[id]/Courses.vue?macro=true";
import { default as indexycXMHUeShGMeta } from "/root/project/frontend/apps/b2c/pages/new/[category]/[id]/index.vue?macro=true";
import { default as _91id_934xS3hxEdEkMeta } from "/root/project/frontend/apps/b2c/pages/new/[category]/course/[id].vue?macro=true";
import { default as indexQ5T0wRBq4CMeta } from "/root/project/frontend/apps/b2c/pages/new/[category]/index.vue?macro=true";
import { default as indexILRF8mipLyMeta } from "/root/project/frontend/apps/b2c/pages/privacy-and-policy/index.vue?macro=true";
import { default as applicationxbVKWCl1pNMeta } from "/root/project/frontend/apps/b2c/pages/profile/application.vue?macro=true";
import { default as compareDlPljp1p99Meta } from "/root/project/frontend/apps/b2c/pages/profile/compare.vue?macro=true";
import { default as documentscKn4OZ3chsMeta } from "/root/project/frontend/apps/b2c/pages/profile/documents.vue?macro=true";
import { default as favoritesCbSxvRNjCxMeta } from "/root/project/frontend/apps/b2c/pages/profile/favorites.vue?macro=true";
import { default as indexxr95vJ1hGhMeta } from "/root/project/frontend/apps/b2c/pages/profile/index.vue?macro=true";
import { default as notificationsnBEUOV46F9Meta } from "/root/project/frontend/apps/b2c/pages/profile/notifications.vue?macro=true";
import { default as offersJ5FGyQvOywMeta } from "/root/project/frontend/apps/b2c/pages/profile/offers.vue?macro=true";
import { default as referralh2H9HkG9VfMeta } from "/root/project/frontend/apps/b2c/pages/profile/referral.vue?macro=true";
import { default as profileju9f6S0ISJMeta } from "/root/project/frontend/apps/b2c/pages/profile.vue?macro=true";
import { default as indexf2LLwAAR6MMeta } from "/root/project/frontend/apps/b2c/pages/quickapply/index.vue?macro=true";
import { default as _91category_93_45courseL4s0A3gF9DMeta } from "/root/project/frontend/apps/b2c/pages/search/[category]-course.vue?macro=true";
import { default as _91category_93xEfthBEBFaMeta } from "/root/project/frontend/apps/b2c/pages/search/[category].vue?macro=true";
import { default as indexCi5fJj1fQcMeta } from "/root/project/frontend/apps/b2c/pages/subject/[id]/index.vue?macro=true";
import { default as indexp3jJ8rL09vMeta } from "/root/project/frontend/apps/b2c/pages/terms-and-conditions/index.vue?macro=true";
import { default as indexfCa17TO5S9Meta } from "/root/project/frontend/apps/b2c/pages/thank-you/index.vue?macro=true";
import { default as _91id_93FbjM5uqq1sMeta } from "/root/project/frontend/apps/b2c/pages/university/[id].vue?macro=true";
import { default as _91id_93EkX6pQx8VnMeta } from "/root/project/frontend/apps/b2c/pages/university/course/[id].vue?macro=true";
import { default as indexk1Rx7YBR9lMeta } from "/root/project/frontend/apps/b2c/pages/why-acceptix/index.vue?macro=true";
export default [
  {
    name: "about-us",
    path: "/about-us",
    meta: index2n1MxXcUoAMeta || {},
    component: () => import("/root/project/frontend/apps/b2c/pages/about-us/index.vue")
  },
  {
    name: "apply",
    path: "/apply",
    component: () => import("/root/project/frontend/apps/b2c/pages/apply/index.vue")
  },
  {
    name: "apply-institute",
    path: "/apply/institute",
    component: () => import("/root/project/frontend/apps/b2c/pages/apply/institute.vue")
  },
  {
    name: "apply-new",
    path: "/apply/new",
    component: () => import("/root/project/frontend/apps/b2c/pages/apply/new/index.vue")
  },
  {
    name: "apply-review",
    path: "/apply/review",
    component: () => import("/root/project/frontend/apps/b2c/pages/apply/review.vue")
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: forgot_45password99o1FuZUChMeta || {},
    component: () => import("/root/project/frontend/apps/b2c/pages/auth/forgot-password.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginBDzoMYYdnGMeta || {},
    component: () => import("/root/project/frontend/apps/b2c/pages/auth/login.vue")
  },
  {
    name: "auth-new-password",
    path: "/auth/new-password",
    meta: new_45passwordkucjtRPEpeMeta || {},
    component: () => import("/root/project/frontend/apps/b2c/pages/auth/new-password.vue")
  },
  {
    name: "auth-signup",
    path: "/auth/signup",
    meta: signupXmPXmlkGYYMeta || {},
    component: () => import("/root/project/frontend/apps/b2c/pages/auth/signup.vue")
  },
  {
    name: "auth-verify",
    path: "/auth/verify",
    meta: verifyHxlooHpVtJMeta || {},
    component: () => import("/root/project/frontend/apps/b2c/pages/auth/verify.vue")
  },
  {
    name: "book-appointment",
    path: "/book-appointment",
    component: () => import("/root/project/frontend/apps/b2c/pages/book-appointment/index.vue")
  },
  {
    name: "course-id",
    path: "/course/:id()",
    component: () => import("/root/project/frontend/apps/b2c/pages/course/[id]/index.vue")
  },
  {
    name: "destinations",
    path: "/destinations",
    meta: indexyaZfY1TnuNMeta || {},
    component: () => import("/root/project/frontend/apps/b2c/pages/destinations/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/root/project/frontend/apps/b2c/pages/index.vue")
  },
  {
    name: "institute-id",
    path: "/institute/:id()",
    meta: _91id_93YqgpTaVjRGMeta || {},
    component: () => import("/root/project/frontend/apps/b2c/pages/institute/[id].vue")
  },
  {
    name: "institutes-id",
    path: "/institutes/:id()",
    meta: _91id_93mv8NHubUPwMeta || {},
    component: () => import("/root/project/frontend/apps/b2c/pages/institutes/[id].vue")
  },
  {
    name: "institutes-course-id",
    path: "/institutes/course/:id()",
    meta: _91id_9381hERy8HZxMeta || {},
    component: () => import("/root/project/frontend/apps/b2c/pages/institutes/course/[id].vue")
  },
  {
    name: "listApplication-id",
    path: "/listApplication/:id()",
    component: () => import("/root/project/frontend/apps/b2c/pages/listApplication/[id]/index.vue")
  },
  {
    name: "listApplication-files-id",
    path: "/listApplication/files/:id()",
    component: () => import("/root/project/frontend/apps/b2c/pages/listApplication/files/[id].vue")
  },
  {
    name: "listApplication",
    path: "/listApplication",
    component: () => import("/root/project/frontend/apps/b2c/pages/listApplication/index.vue")
  },
  {
    name: "new-category-id-Courses",
    path: "/new/:category()/:id()/Courses",
    component: () => import("/root/project/frontend/apps/b2c/pages/new/[category]/[id]/Courses.vue")
  },
  {
    name: "new-category-id",
    path: "/new/:category()/:id()",
    component: () => import("/root/project/frontend/apps/b2c/pages/new/[category]/[id]/index.vue")
  },
  {
    name: "new-category-course-id",
    path: "/new/:category()/course/:id()",
    component: () => import("/root/project/frontend/apps/b2c/pages/new/[category]/course/[id].vue")
  },
  {
    name: "new-category",
    path: "/new/:category()",
    component: () => import("/root/project/frontend/apps/b2c/pages/new/[category]/index.vue")
  },
  {
    name: "privacy-and-policy",
    path: "/privacy-and-policy",
    component: () => import("/root/project/frontend/apps/b2c/pages/privacy-and-policy/index.vue")
  },
  {
    name: profileju9f6S0ISJMeta?.name,
    path: "/profile",
    component: () => import("/root/project/frontend/apps/b2c/pages/profile.vue"),
    children: [
  {
    name: "profile-application",
    path: "application",
    component: () => import("/root/project/frontend/apps/b2c/pages/profile/application.vue")
  },
  {
    name: "profile-compare",
    path: "compare",
    component: () => import("/root/project/frontend/apps/b2c/pages/profile/compare.vue")
  },
  {
    name: "profile-documents",
    path: "documents",
    component: () => import("/root/project/frontend/apps/b2c/pages/profile/documents.vue")
  },
  {
    name: "profile-favorites",
    path: "favorites",
    component: () => import("/root/project/frontend/apps/b2c/pages/profile/favorites.vue")
  },
  {
    name: "profile",
    path: "",
    component: () => import("/root/project/frontend/apps/b2c/pages/profile/index.vue")
  },
  {
    name: "profile-notifications",
    path: "notifications",
    component: () => import("/root/project/frontend/apps/b2c/pages/profile/notifications.vue")
  },
  {
    name: "profile-offers",
    path: "offers",
    component: () => import("/root/project/frontend/apps/b2c/pages/profile/offers.vue")
  },
  {
    name: "profile-referral",
    path: "referral",
    component: () => import("/root/project/frontend/apps/b2c/pages/profile/referral.vue")
  }
]
  },
  {
    name: "quickapply",
    path: "/quickapply",
    component: () => import("/root/project/frontend/apps/b2c/pages/quickapply/index.vue")
  },
  {
    name: "search-category-course",
    path: "/search/:category()-course",
    meta: _91category_93_45courseL4s0A3gF9DMeta || {},
    component: () => import("/root/project/frontend/apps/b2c/pages/search/[category]-course.vue")
  },
  {
    name: "search-category",
    path: "/search/:category()",
    meta: _91category_93xEfthBEBFaMeta || {},
    component: () => import("/root/project/frontend/apps/b2c/pages/search/[category].vue")
  },
  {
    name: "subject-id",
    path: "/subject/:id()",
    meta: indexCi5fJj1fQcMeta || {},
    component: () => import("/root/project/frontend/apps/b2c/pages/subject/[id]/index.vue")
  },
  {
    name: "terms-and-conditions",
    path: "/terms-and-conditions",
    component: () => import("/root/project/frontend/apps/b2c/pages/terms-and-conditions/index.vue")
  },
  {
    name: "thank-you",
    path: "/thank-you",
    component: () => import("/root/project/frontend/apps/b2c/pages/thank-you/index.vue")
  },
  {
    name: "university-id",
    path: "/university/:id()",
    meta: _91id_93FbjM5uqq1sMeta || {},
    component: () => import("/root/project/frontend/apps/b2c/pages/university/[id].vue")
  },
  {
    name: "university-course-id",
    path: "/university/course/:id()",
    meta: _91id_93EkX6pQx8VnMeta || {},
    component: () => import("/root/project/frontend/apps/b2c/pages/university/course/[id].vue")
  },
  {
    name: "why-acceptix",
    path: "/why-acceptix",
    meta: indexk1Rx7YBR9lMeta || {},
    component: () => import("/root/project/frontend/apps/b2c/pages/why-acceptix/index.vue")
  }
]